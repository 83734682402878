<template>

  <button class="btn btn-primary" @click="show()" v-if="!on"> Load Feature </button>
  <button v-else class="btn btn-danger" @click="dismiss()"> Cancel </button>

  <div v-if="on">
    <label>Custom feature Name:</label> <br>
    <input type="text" v-model="featName"><br/>

    <label>Feature Category:</label>
    <select v-model="selectedCat" @change="getSubcats">
      <option v-for="cat in cats" v-bind:key="cat.id" v-bind:value="cat.name">
        {{cat.name}}
      </option>
    </select><br/>

    <label>Feature Subcategory:</label>
    <select v-model="selectedSubcat" @change="getRegionsAndFeats">
      <option v-for="subcat in subcats" v-bind:key="subcat.id" v-bind:value="subcat.name">
        {{subcat.name}}
      </option>
    </select><br/>

    <label>Region:</label>
    <select v-model="selectedRegion" @change="getFeats">
      <option v-for="reg in regions" v-bind:key="reg.id" v-bind:value="reg.name">
        {{reg.name}}
      </option>
    </select><br/>

    <label>Feature:</label>
    <select v-model="selectedFeatId">
      <option v-for="feat in feats" v-bind:key="feat.id" v-bind:value="feat.id">
        {{feat.name}}
      </option>
    </select><br/>

    <button v-if="selectedFeatId !== -1 && featName !== ''" class="btn btn-success" @click="addFeature()"> Confirm </button>
  </div>

</template>

<script>
import axios from "axios";

export default {
  name: "FeatureLoader",
  emits: ['created'],

  data (){
    return {
      on: false,

      featName: '',
      featWkt: '',

      useApi: true,

      inputType: 'JSON',

      cats: [],
      subcats: [],
      regions: [],
      feats: [],

      selectedCat: '',
      selectedSubcat: '',
      selectedRegion: '',
      selectedFeatId: -1
    }
  },

  methods: {
    show: function (){
      console.log("Showing dialog")
      this.featName = '';
      this.featWkt = '';
      this.on = true;

      this.getCats();
    },

    dismiss: function (){
      this.on = false;
    },

    addFeature: async function (){
      this.dismiss();

      var payload = {
        'featId': this.selectedFeatId
      };

      var resp = await axios.post('/api/geometry/load/definition', payload);
      if (resp.status === 200){

        var featJson = resp.data.geometry;

        var newFeat = {
          name: this.featName,
          geoJ: featJson,
          useApi: false,
          inputType: this.inputType
        }
        this.$emit('created', newFeat);
      }

    },

    getCats: async function (){
      var resp = await axios.get('/api/geometry/load/categories');

      if (resp.status === 200){
        for(var i = 0; i < resp.data.categories.length; i++){
          this.cats.push(resp.data.categories[i]);
        }
        //self.cats = resp.data.categories;
      }

      console.log("Cats:", this.cats)
    },

    getSubcats: async function (){
      var payload = {
        'category': this.selectedCat
      }
      var resp = await axios.post('/api/geometry/load/subcategories', payload)

      if (resp.status === 200){
        this.subcats = resp.data.subcategories;
        // for(var i = 0; i < resp.data.categories.length; i++){
        //   this.cats.push(resp.data.categories[i]);
        // }
      }

      console.log("Subcats", this.subcats);
    },

    getRegionsAndFeats: async function (){
      await this.getRegions();
      await this.getFeats();
    },

    getRegions: async function (){
      var payload = {
        'category': this.selectedCat,
        'subcategory': this.selectedSubcat,
      };

      var resp = await axios.post('/api/geometry/load/regions', payload);

      if (resp.status === 200){
        this.regions = resp.data.regions;
      }

      console.log("Regions", this.regions);
    },

    getFeats: async function (){
      var payload = {
        'category': this.selectedCat,
        'subcategory': this.selectedSubcat,
        'region': this.region
      };

      var resp = await axios.post('/api/geometry/load/features', payload);

      if (resp.status === 200){
        this.feats = resp.data.features;
      }

      console.log("Features", this.feats)
    }
  },

  mounted() {
    this.cats = [];
    this.subcats = [];
  }
}
</script>

<style scoped>

label{
  padding-right: 1em;
}

</style>