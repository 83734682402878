<template>
<button class="btn btn-primary" @click="show()" v-if="!on"> Add Feature </button>
<button v-else class="btn btn-danger" @click="dismiss()"> Cancel </button>

  <div v-if="on">
    <label>Feature Name:</label> <br>
    <input type="text" v-model="featName"> <br>
    <label>Feature Definition:</label> <br>
    <textarea v-model="featWkt" rows="10" cols="30"></textarea> <br>
    <label>Format:</label>
    <select v-model="inputType">
      <option value="WKT" selected>Well Known Text</option>
      <option value="JSON">GeoJSON</option>
    </select>
    <br>
    <input type="checkbox" v-model="useApi"> Use backend to process geometry (recommended) <br>

    <div v-if="!useApi">
      <br>
      Note: Using backend to process geometry uses
      <a href="https://pypi.org/project/shapely/" target="_blank">Shapely</a> +
      <a href="https://libgeos.org/" target="_blank">GEOS</a> at its core, which is very robust. Using the frontend
      to process geometry relies on a Javascript library called
      <a href="https://github.com/arthur-e/Wicket" target="_blank">Wicket</a>. Wicket does not
      handle invalid or complicated geometries very well.
    </div>
    <br>
    <div v-if="(inputType === 'WKT' || useApi) && featName !== ''">
      <button class="btn btn-success" @click="addFeature()"> Confirm </button>
    </div>
    <div v-else>
      Front-end geometry conversion can only handle WKT.
    </div>
  </div>

</template>

<script>


export default {
  name: "FeatureCreator",
  emits: ['created'],

  data (){
    return {
      on: false,

      featName: '',
      featWkt: '',

      useApi: true,

      inputType: 'WKT'
    }
  },

  methods: {
    show: function (){
      console.log("Showing dialog")
      this.featName = '';
      this.featWkt = '';
      this.on = true;
    },

    dismiss: function (){
      this.on = false;
    },

    addFeature: function (){
      this.dismiss();
      var newFeat = {
        name: this.featName,
        geoJ: this.featWkt,
        geom: this.featWkt,
        useApi: this.useApi,
        inputType: this.inputType
      }
      this.$emit('created', newFeat);

    }
  }

}
</script>

<style scoped>

label{
  padding-right: 1em;
}

</style>