<template>

  <body>
  <div class="row">
    <div class="col-12">
      <h2>About This Site</h2>

      <p>
        While there is no shortage of
        <a href="https://gis.stackexchange.com/questions/115433/online-wkt-and-geojson-viewer">online WKT and GeoJSON
          viewers</a>, all of them are missing one feature: the ability to view several individual geometries without
        manually turning them into a geometry collection or multi-something. This feature would be especially useful
        for people who frequently debug code that handles geospatial data and want to check their work without
        using a GIS or switching between several tabs of one of the sites listed in the above thread. This tool is
        meant to fill that void.
      </p>

      <p>
        This is a free and open source project licensed under the GPL version 3. Source code is available on
        <a href="https://gitlab.com/bek3/wellknowntext-site" target="_blank">Gitlab</a>. If you have an improvement you
        would like to make, please feel free to fork the project and send me a pull request!
      </p>

      <h3>Credits</h3>

      <p>
        Pre-built geometry is from <a href="https://github.com/johan/world.geo.json" target="_blank">world.geo.json</a>.
      </p>

    </div>
  </div>

  <div class="row">
    <div class="col-8">
      <h2>About The Author</h2>
      <p>
        My name is Brendan Kristiansen, I am a computer scientist living with my wife and our cat in Sheridan, WY, USA.
        I specialize in embedded systems, mapping, and data collection. Because most of the code I write is meant to
        collect or process data, I have never spent much time learning front-end website development. Since the best
        way to learn is to practice, I decided to take this project on to learn the Vue framework.
      </p>

      <p>
        I designed this site to be a useful tool for me at work while I debug code that generates or modifies geometry.
        I hope other people find it useful enough to incorporate it into their workflow as
        well. If you like this tool, please consider buying me a coffee in the menu above to help fund hosting and domain
        renewal. You may view my personal website <a href="http://www.bek.sh" target="_blank">here</a>.
      </p>

    </div>
    <div class="col-4">
      <img src="../../img/with-olaf.jpg" class="photo"> <br/><br/>
    </div>
  </div>

  </body>

</template>

<script>

export default {
  name: "AboutModal",
}
</script>

<style scoped>

.photo {
  width: 250px;
}

p, h2, h3{
  margin: 20px;
}



</style>