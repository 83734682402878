<template>
<body>
<h2> WKT Sandbox Feedback </h2>
<div v-if="!sent">
  <div class="row">
    <div class="col-1 label">
      Kind:
    </div>
    <div class="col-3">
      <select v-model="kind">
        <option value="suggestion">Suggestion</option>
        <option value="bug">Bug Report</option>
        <option value="other">Other</option>
      </select>
    </div>
  </div>
  <div class="row">
    <div class="col-1 label">
      Name:
    </div>
    <div class="col-3">
      <input type="text" v-model="name">
    </div>
  </div>
  <div class="row">
    <div class="col-1 label">
      Email:
    </div>
    <div class="col-3">
      <input type="text" v-model="email">
    </div>
  </div>
  <div class="row">
    <div class="col-3 label">Message:</div>
    <div class="col-3 label">Geometry that broke this site:</div>
  </div>
  <div class="row">
    <div class="col-3">
      <textarea v-model="msg"/>
    </div>
    <div class="col-3">
      <textarea v-model="geom"/>
    </div>
  </div>

  <div v-if="readyToSend()">
    <button class="btn btn-success" @click="submitFeedback()">Submit</button>
  </div>
  <div v-else>
    <button class="btn btn-success disabled">Submit</button>
  </div>
</div>
<div v-else>
  Your feedback has been received. You will be contacted with questions if necessary. Thank you! <br>
  <button class="btn btn-primary" @click="reset">
    More feedback
  </button>
</div>


</body>
</template>

<script>
import axios from "axios";

export default {
  name: "FeedbackTab",

  data (){
    return {
      kind: '',
      name: '',
      email: '',
      msg: '',
      geom: '',

      sent: false,
    }
  },

  methods: {
    readyToSend: function (){
      if (this.name === '') return false;
      if (this.email === '') return false;
      if (this.msg === '') return false;

      return true;
    },

    submitFeedback: function (){
      var payload = {
        email: this.email,
        name: this.name,
        type: this.kind,
        message: this.msg,
        geom: this.geom
      }

      axios.post('/api/feedback', payload);

      this.sent=true;
    },

    reset: function(){
      this.name = '';
      this.email = '';
      this.kind = '';
      this.msg = '';
      this.geom = '';
      this.sent = false;
    }
  },
}

</script>

<style scoped>

body {
  margin: 12px;
}

textarea {
  width: 100%;
  height: 200px;
}

</style>