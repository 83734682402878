<template>
  <head>
    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
  </head>
  <body>
  <main id="bodyWrapper">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-10 col-8">
          <div id="map"></div>
        </div>
        <div class="col-xl-2 col-4">
          <div v-if="currentErrMsg !== ''" class="alert alert-danger" role="alert">
            <b>{{currentErrMsg}}</b> <hr>
            <button class="btn btn-dark" @click="dismissErrorMsg()"> Dismiss </button>
          </div>
          <h5>Mapped Features:</h5>
          <ul>
            <li v-for="feat in wktFeatures" v-bind:key="feat.name">
              <FeatureEditor :feature="feat" @changed="updateMap($event)" @deleted="deleteFeature($event)"></FeatureEditor>
            </li>
            <br>
            <li>
              <FeatureCreator @created="addFeature($event)"/>
              <FeatureLoader @created="addFeature($event)"/>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </main>

  </body>

<!-- Hello this is a map-->
</template>

<script>

import "leaflet/dist/leaflet.css";
import Wkt from "wicket";
import L from "leaflet";
import axios from "axios";

import FeatureEditor from "@/components/FeatureEditor";
import FeatureCreator from "@/components/FeatureCreator";
import FeatureLoader from "@/components/FeatureLoader";

export default {
  name: "MapComponent",
  components: {FeatureLoader, FeatureEditor, FeatureCreator},
  data (){
    return {

      map: null,

      basemaps: {},

      features: {},

      layerCtrl: null,

      defaultIcon: L.icon({ iconUrl: "/leaflet-resources/marker-icon.png" }),

      defaultFeat: {
        name: 'Wyoming',
        inputType: 'WKT',
        useApi: false,
        wkt: 'POLYGON((-110.99999999999999 45,-104.00000000000001 45,-104.00000000000001 41.00000000000003,-110.99999999999999 41.00000000000003,-110.99999999999999 45))',
        geom: null
      },

      wktFeatures: [],

      currentErrMsg: ''
    }
  },

  methods: {

    dismissErrorMsg: function(){
      this.currentErrMsg = '';
    },

    wktToGeojsonViaApi: async function(wktFeat){
      let payload = {
        'from': 'wkt',
        'to': 'json',
        'geometry': wktFeat.geom
      };
      var msg = '';
      var resp = await axios.post('/api/geometry/convert', payload).catch(
          function (error) {
            if (error.response){
              console.log(error.response);
              msg = error.response.data['error_msg'];
            }
          }
      );
      if (msg !== ''){
        this.currentErrMsg = msg;
        return null;
      }

      if (resp.status === 200) {
        return resp.data['geometry'];
      }else{
        this.currentErrMsg = resp.data['error_msg']
        return null;
      }
    },

    geoJsonToWktViaApi: async function(geojson){
      let payload = {
        'from': 'json',
        'to': 'wkt',
        'geometry': geojson
      };
      var msg = '';
      var resp = await axios.post('/api/geometry/convert', payload).catch(
          function (error) {
            if (error.response){
              console.log(error.response);
              msg = error.response.data['error_msg'];
            }
          }
      );
      if (msg !== ''){
        this.currentErrMsg = msg;
        return null;
      }

      if (resp.status === 200) {
        return resp.data['geometry'];
      }else{
        //this.currentErrMsg = resp.data['error_msg']
        if (resp.data.err_msg !== null)
          throw "Error during geometry conversion:"+resp.data.err_msg;
        else throw "Unhandled backend error :(";
      }
    },

    wktToLeafletObjViaApi: async function(wktFeat){
      let geojson = await this.wktToGeojsonViaApi(wktFeat);
      return L.geoJson(geojson);
    },

    wktFeatToLeafletObj: function(wktFeat){
      var wkt = new Wkt.Wkt();
      wkt.read(wktFeat.wkt);
      console.log("wkt object: ", wkt);
      let geo = wkt.toJson();
      return L.geoJson(geo);
    },

    updateMap: function(updatedFeat){

      if (this.features[updatedFeat.name] != null) return;

      console.log("Updated: ", updatedFeat);
      var leafletObj = this.wktFeatToLeafletObj(updatedFeat);
      // this.features[updatedFeat.name] = leafletObj;
      console.log("Features:", this.features);
      var previousFeat = this.features[updatedFeat.og.name];
      previousFeat.removeFrom(this.map);
      this.layerCtrl.removeLayer(previousFeat);
      leafletObj.addTo(this.map);
      this.layerCtrl.addOverlay(leafletObj, updatedFeat.name);
    },

    deleteFeature: function(removedFeat){
      console.log(removedFeat);

      var previousFeat = this.features[removedFeat.og.name];
      previousFeat.removeFrom(this.map);
      this.layerCtrl.removeLayer(previousFeat);
      this.wktFeatures.pop(previousFeat)
    },

    addFeature: async function(newFeat){

      if (this.features[newFeat.name] != null){
        this.currentErrMsg = 'Duplicate feature name';
        return;
      }

      console.log("Adding to map", newFeat)
      var leafletObj = null;

      try{
        if (newFeat.inputType === 'WKT'){
          if (newFeat.geom !== null) newFeat.geometry = newFeat.geom;
          if (newFeat.useApi) leafletObj = await this.wktToLeafletObjViaApi(newFeat);
          else leafletObj = this.wktFeatToLeafletObj(newFeat);
        }else if (newFeat.inputType === 'JSON') {
          leafletObj = L.geoJson(JSON.parse(newFeat.geoJ));
          newFeat.wkt = await this.geoJsonToWktViaApi(newFeat.geoJ);
        }
      }catch (e) {
        this.currentErrMsg = e;
        return;
      }

      console.log("Leaflet obj", leafletObj)
      if (leafletObj === null) return;
      leafletObj.addTo(this.map);
      this.layerCtrl.addOverlay(leafletObj, newFeat.name);
      this.features[newFeat.name] = leafletObj;
      this.wktFeatures.push(newFeat);
    },
  },

  onLocationError(e) {
    alert(e.message);
  },

  mounted(){

    L.Marker.prototype.options.icon = this.defaultIcon;

    this.basemaps.OpenStreetMap = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '© OpenStreetMap'
    });
    this.basemaps.OpenTopoMap = L.tileLayer('https://tile.opentopomap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '© OpenTopoMap'
    });
    this.basemaps['USGS US Topo'] = L.tileLayer('https://basemap.nationalmap.gov/arcgis/rest/services/USGSTopo/MapServer/tile/{z}/{y}/{x}', {
      maxZoom: 20,
      attribution: 'Tiles courtesy of the <a href="https://usgs.gov/">U.S. Geological Survey</a>'
    });
    this.basemaps['USGS US Imagery'] = L.tileLayer('https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryOnly/MapServer/tile/{z}/{y}/{x}', {
      maxZoom: 20,
      attribution: 'Tiles courtesy of the <a href="https://usgs.gov/">U.S. Geological Survey</a>'
    });

    // this.wktFeatures.push(this.defaultFeat);
    // this.features[this.defaultFeat.name] = defaultFeat;
    console.log('features on map: ', this.features);

    this.map = L.map('map', {
      center: [38, -95],
      zoom: 4,
      layers: [this.basemaps.OpenStreetMap]
    });

    this.map.on('locationerror', this.onLocationError);
    //this.map.locate({setView: true, maxZoom:5});
    this.layerCtrl = L.control.layers(this.basemaps, this.features);
    this.layerCtrl.addTo(this.map);

    this.addFeature(this.defaultFeat)
  }

}

</script>
<style scoped>
@import'~bootstrap/dist/css/bootstrap.css';

html, body {
  margin: 0;
  height: 100%;
}

/*#
*/

#map {
  width: inherit;
  height: 90%;
  position: fixed;
  left: 0px;
  /*z-index: 10;*/
}

#bodyWrapper > nav {
  flex: 0 0 56px;
  overflow: hidden;
}

ul {
  list-style-type: none;
}


</style>