<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light" id="wktnavbar">
    <a class="navbar-brand" href="#">WellKnownText Sandbox</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" href="#" @click="showAbout()" v-if="!aboutVisible">About</a>
          <a class="nav-link active" href="#" @click="hideAbout()" v-else>About</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" :class="{disabled: apiVer === '', active: feedbackVisible}" href="#"
             @click="toggleFeedback()">Feedback</a>
        </li>
        <li class="nav-item">
          <a class="nav-link disabled" href="#">Tutorial</a>
        </li>
        <li></li>
        <li class="nav-item">
          <a class="nav-link" target="_blank" href="https://www.buymeacoffee.com/bek3">
            <img src="https://www.buymeacoffee.com/assets/img/BMC-btn-logo.svg" alt="Buy Brendan a coffee"/>
            Buy me a coffee
          </a>
        </li>
      </ul>
      <!-- see this: https://github.com/momocow/tukiyomi-docs/blob/master/docs/.vuepress/components/BuyMeACoffee.vue#LL2C2-L6C7 -->

    </div>
    <span class="navbar-text">
      <div  v-if="aboutVisible || feedbackVisible" class="btn btn-close" id="close-top" @click="hideAll()"/>
      <span v-if="apiVer !== ''">Backend Version: {{apiVer}} ({{apiDate}})</span>
      <span v-else>Unable to reach API <button @click="initApiConnection()" class="btn btn-dark">Retry</button></span>
    </span>
  </nav>
  <div v-for="message in messages" v-bind:key="message.msg" class="alert alert-warning" role="alert">
    <b>Notice:</b> {{message.msg}}
  </div>
  <div v-if="aboutVisible">
    <AboutModal/>
  </div>
  <div v-if="feedbackVisible">
    <FeedbackTab/>
  </div>
  <div v-if="aboutVisible || feedbackVisible"><hr/></div>
  <MapComponent />
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import MapComponent from "@/components/MapComponent";
import AboutModal from "@/components/About";
import FeedbackTab from "@/components/FeedbackTab";
import axios from "axios";

export default {
  name: 'App',
  components: {
    FeedbackTab,
    MapComponent,
    AboutModal
    // HelloWorld
  },

  data (){
    return {
      aboutVisible: false,
      feedbackVisible: false,

      apiDate: '',
      apiVer: '',

      messages: []
    }
  },

  methods: {

    initApiConnection: async function(){
      var resp = await axios.get('/api')

      if (resp.status === 200){
        console.log("API Info:", resp);
        var data = resp.data

        this.apiDate = data.build_date;
        this.apiVer = data.version;
        this.messages = data.messages;
      }

    },

    showFeedback: function (){
      this.hideAll();
      this.feedbackVisible = true;
    },

    hideFeedback: function(){
      this.feedbackVisible = false
    },

    toggleFeedback: function (){
      if (this.feedbackVisible) this.hideFeedback();
      else this.showFeedback();
    },

    showAbout: function(){
      this.hideAll();
      this.aboutVisible = true;
    },

    hideAbout: function (){
      this.aboutVisible = false;
    },

    hideAll: function(){
      this.aboutVisible = false;
      this.feedbackVisible = false;
    }
  },

  beforeMount(){
    this.initApiConnection();
  }
}
</script>

<style>
@import'~bootstrap/dist/css/bootstrap.css';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
  /*margin-top: 60px;*/
}

#wktnavbar{
  margin-left: 12px;
  padding-bottom: 0px;
}

#close-top {
  margin-right: 8px;
}

.active{
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: darkseagreen;
}

.navbar-text{
  margin-right: 12px;
}

</style>
