<template>
  <div id="featureEditPanel">
    <button class="btn-danger btn btn-sm" @click="onDelete">
      <b>X</b>
    </button>
    <button class="btn btn-info btn-sm" @click="toggle">
      <span v-if="on">Finish</span>
      <span v-else>Manage</span>
    </button> {{ name }}

    <div v-if="on">
      <label>Feature Name:</label> <br>
      <input type="text" v-model="name"> <br>
      <label>Geometry:</label><br>
      <div v-if="showWkt">
        <button class="btn btn-dark geometry-tab" @click="activateWkt()">WKT</button>
        <button class="btn btn-dark geometry-tab tab-inctive" @click="deactivateWkt()">GeoJSON</button>
      </div>
      <div v-else>
        <button class="btn btn-dark geometry-tab tab-inctive" @click="activateWkt()">WKT</button>
        <button class="btn btn-dark geometry-tab" @click="deactivateWkt()">GeoJSON</button>
      </div>
      <textarea disabled v-model="currentRep"></textarea>
      <button v-if="!showWkt && !prettyJson" class="btn btn-info" @click="prettyprintJson()">Pretty-print</button>
      <button v-else-if="!showWkt && prettyJson" class="btn btn-info" disabled @click="prettyprintJson()">Pretty-print</button>
      <hr>
    </div>
  </div>
</template>

<script>

import Wkt from "wicket";

export default {
  name: "FeatureEditor",
  props: ['feature'],
  emits: ['changed', 'deleted'],

  data (){
    return {
      name: this.feature.name,
      on: false,
      showWkt: true,
      prettyJson: false,

      currentRep: '',

      lastEmission: this.feature,
    }
  },

  methods: {
    onChange: function(){
      var newFeat = this.feature;
      var f = {
        name: this.name,
        wkt: this.feature.wkt,
        og: this.lastEmission,
      };
      this.setGeometryRepresentationFromWkt(f.wkt);

      newFeat.enabled = !this.on;
      console.log("Emitting", f);
      this.$emit('changed', f);
      this.lastEmission = f;
    },

    onDelete: function (){
      var f = {
        name: this.name,
        wkt: this.feature.wkt,
        og: this.lastEmission,
      };
      this.$emit('deleted', f);
      this.lastEmission = f;
    },

    toggle: function(){
      if (this.on) this.onChange();
      this.on = !this.on;
    },

    activateWkt: function (){
      this.showWkt = true;
      this.prettyJson = false;
      this.setGeometryRepresentationFromWkt(this.feature.wkt);
    },

    deactivateWkt: function(){
      this.showWkt = false;
      this.setGeometryRepresentationFromWkt(this.feature.wkt);
    },

    prettyprintJson: function(){
      this.prettyJson = true;
      this.setGeometryRepresentationFromWkt(this.feature.wkt);
    },

    setGeometryRepresentationFromWkt: function(text){
      if (this.showWkt){
        this.currentRep = text;
      }else{
        var wkt = new Wkt.Wkt();
        wkt.read(text);
        if (!this.prettyJson) this.currentRep = JSON.stringify(wkt.toJson());
        else this.currentRep = JSON.stringify(wkt.toJson(), null, 2);
      }
    }
  },

  beforeMount() {
    console.log('feature editor before mount', this);
    this.setGeometryRepresentationFromWkt(this.feature.wkt);
  }
}
</script>

<style scoped>

#featureEditPanel{
  margin-bottom: 5px;
}

textarea{
  width: 100%;
  height: 200px;
  box-sizing: border-box;
}

.geometry-tab{
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.tab-inctive{
  background: dimgrey;
}

</style>